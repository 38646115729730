
<template>
  <div>
    <div class="p-padding">
      <div class="prism-player" id="J_prismPlayer"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
      filterText: "",
      treeKey: "",
      defaultProps: {
        // 可把label，children转换成自己想要的名称，如lable：name
        children: "children",
        label: "label",
      },
      data2: [
        {
          id: 1,
          label: "一级1",
          children: [
            {
              id: 2,
              label: "二级 1-1",
              children: [
                {
                  id: 333,
                  label: "三级 1-1-1",
                  children: [
                    {
                      id: 444,
                      label: "四级 1-1-1",
                    },
                    {
                      id: 445,
                      label: "四级 1-1-2",
                    },
                  ],
                },
                {
                  id: 335,
                  label: "三级 1-1-2",
                },
              ],
            },
          ],
        },
      ],
    };
  },
  mounted: function () {
    // 初始化播放器
    var player = new Aliplayer({
      id: "J_prismPlayer", // 容器id
      source:
        "http://cloud.video.taobao.com/play/u/2554695624/p/1/e/6/t/1/fv/102/28552077.mp4", //视频地址
      cover: "http://cdn.img.mtedu.com/images/assignment/day_3.jpg", //播放器封面图
      width: "500px",
      height: "500px",
      autoplay: true,
      isLive: false,
      rePlay: false,
      playsinline: true,
      preload: true,
      controlBarVisibility: "hover",
      useH5Prism: true,
      skinLayout: [
        {
          name: "bigPlayButton",
          align: "blabs",
          x: 30,
          y: 80,
        },
        {
          name: "H5Loading",
          align: "cc",
        },
        {
          name: "errorDisplay",
          align: "tlabs",
          x: 0,
          y: 0,
        },
        {
          name: "infoDisplay",
        },
        {
          name: "tooltip",
          align: "blabs",
          x: 0,
          y: 56,
        },
        {
          name: "thumbnail",
        },
        {
          name: "controlBar",
          align: "blabs",
          x: 0,
          y: 0,
          children: [
            {
              name: "progress",
              align: "blabs",
              x: 0,
              y: 44,
            },
            {
              name: "playButton",
              align: "tl",
              x: 15,
              y: 12,
            },
            {
              name: "timeDisplay",
              align: "tl",
              x: 10,
              y: 7,
            },
            {
              name: "fullScreenButton",
              align: "tr",
              x: 10,
              y: 12,
            },
          ],
        },
      ],
    });
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    handleNodeClick(data, node, obj) {
      if (data.id) {
        this.treeId = data.id;
        this.treeDataObj = data;
        this.nodeDataTree = node;
        console.log(data);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>